<template>
    <span>
        {{ text }}
        <v-btn text icon x-small>
            <v-icon v-if="query?.sort === value && query?.sortBy === 'desc'" @click="sortOptions(null)">mdi-arrow-up</v-icon>
            <v-icon v-if="query?.sort === value && query?.sortBy === 'asc'" @click="sortOptions('desc')">mdi-arrow-down</v-icon>
            <v-icon v-if="query?.sort !== value || !query?.sortBy" @click="sortOptions('asc')">mdi-sort</v-icon>
        </v-btn>
    </span>
</template>

<script>
let initQuery = (query = {}) => ({
    ...query,
    sort: query?.sort || null,
    sortBy: query?.sortBy || null,
});
export default {
    props: {
        text: { type: String, default: "" },
        value: { type: String, default: "" },
    },
    data: () => ({
        query: initQuery(),
    }),
    mounted() {
        this.sync();
    },

    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        sortOptions(sortBy) {
            const query = {
                ...this.query,
                sort: this.value,
                sortBy,
            };

            if (!sortBy) {
                delete query.sort;
                delete query.sortBy;
            }

            this.query = query;
            this.$router.push({ query });
        },
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
};
</script>