var render = function render(){
  var _vm$query, _vm$query2, _vm$query3, _vm$query4, _vm$query5, _vm$query6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm._v(" " + _vm._s(_vm.text) + " "), _c('v-btn', {
    attrs: {
      "text": "",
      "icon": "",
      "x-small": ""
    }
  }, [((_vm$query = _vm.query) === null || _vm$query === void 0 ? void 0 : _vm$query.sort) === _vm.value && ((_vm$query2 = _vm.query) === null || _vm$query2 === void 0 ? void 0 : _vm$query2.sortBy) === 'desc' ? _c('v-icon', {
    on: {
      "click": function ($event) {
        return _vm.sortOptions(null);
      }
    }
  }, [_vm._v("mdi-arrow-up")]) : _vm._e(), ((_vm$query3 = _vm.query) === null || _vm$query3 === void 0 ? void 0 : _vm$query3.sort) === _vm.value && ((_vm$query4 = _vm.query) === null || _vm$query4 === void 0 ? void 0 : _vm$query4.sortBy) === 'asc' ? _c('v-icon', {
    on: {
      "click": function ($event) {
        return _vm.sortOptions('desc');
      }
    }
  }, [_vm._v("mdi-arrow-down")]) : _vm._e(), ((_vm$query5 = _vm.query) === null || _vm$query5 === void 0 ? void 0 : _vm$query5.sort) !== _vm.value || !((_vm$query6 = _vm.query) !== null && _vm$query6 !== void 0 && _vm$query6.sortBy) ? _c('v-icon', {
    on: {
      "click": function ($event) {
        return _vm.sortOptions('asc');
      }
    }
  }, [_vm._v("mdi-sort")]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }