<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">
            <list-heading title="회원 목록" v-model="showsSearch" showsFilterButton />
            <user-list-search v-bind="{ showsSearch }" />
            <v-row>
                <v-col cols="auto"> 포인트 합계: {{ summary.totalPoint.format() }} 포인트 </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-btn color="white green--text" class="px-2" @click="excel">
                        <span> <v-icon>mdi-microsoft-excel</v-icon> 엑셀다운로드 </span>
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table :items="users" :headers="usersHeaders" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-slot:[`header.username`]="{ header }">
                    <user-list-sort-options v-bind="{ text: header.text, value: 'username' }"/>
                </template>
                <template v-slot:[`header.name`]="{ header }">
                    <user-list-sort-options v-bind="{ text: header.text, value: 'name' }"/>
                </template>
                <template v-slot:[`header.point`]="{ header }">
                    <user-list-sort-options v-bind="{ text: header.text, value: 'point' }"/>
                </template>
                <template v-slot:[`header.createdAt`]="{ header }">
                    <user-list-sort-options v-bind="{ text: header.text, value: 'createdAt' }"/>
                </template>
                <template slot="header.loginFailedCount">
                    <v-layout align-center>
                        <div>로그인<br />실패횟수</div>
                        <v-tooltip bottom>
                            <v-icon slot="activator" slot-scope="{ on, attrs }" small class="d-inline-block" v-on="on" v-bind="attrs">mdi-alert-circle-outline</v-icon>
                            <div>
                                <div class="font-size-14">* 로그인 실패 횟수는 다음날 자동으로 초기화 됩니다.</div>
                                <div class="font-size-14">* 초기화 버튼을 누르면, 오늘 로그인 실패횟수가 리셋됩니다.</div>
                            </div>
                        </v-tooltip>
                    </v-layout>
                </template>
                <template #[`item.point`]="{ item }">
                    {{ item.point.format() }}
                    <v-icon @click="$refs.userPoint.open(item)">mdi-plus-circle-multiple-outline</v-icon>
                </template>
                <template #[`item.no`]="{ item }">
                    {{ (page - 1) * limit + users.indexOf(item) + 1 }}
                </template>
                <template #[`item.title`]="{ item, value }">
                    <edit-dialog-user-type :value="item" v-on="{ updateItem }"> {{ value }} </edit-dialog-user-type>
                </template>
                <template #[`item.isWarning`]="{ item }">
                    <v-switch v-model="item.isWarning" v-bind="attrs_switch" @change="(isWarning) => update({ _id: item._id, isWarning })" />
                </template>
                <template slot="item.loginFailedCount" slot-scope="{ item }">
                    {{ item.loginFailedCount[$dayjs().format("YYYY-MM-DD")] || 0 }} / 5
                    <v-icon
                        small
                        @click="
                            update({ _id: item._id, loginFailedCount: new Map() });
                            item.loginFailedCount = {};
                        "
                        >mdi-refresh</v-icon
                    >
                </template>
                <template #[`item.createdAt`]="{ item }">
                    {{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-btn small text icon tile :to="`${$route.path}/${item._id}`">
                        <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
        <edit-dialog-user-point ref="userPoint" @init="search" />
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { attrs_switch } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import UserListSearch from "@/components/console/users/user-list-search.vue";
import EditDialogUserType from "@/components/console/users/edit-dialog-user-type.vue";
import EditDialogUserPoint from "@/components/console/users/edit-dialog-user-point.vue";
import UserListSortOptions from "@/components/console/users/user-list-sort-options.vue";

export default {
    components: {
        ListHeading,
        UserListSearch,
        EditDialogUserType,
        EditDialogUserPoint,
        UserListSortOptions
    },
    data: () => ({
        users: [],

        limit: 10,
        summary: { totalCount: 0, totalPoint: 0 },

        loading: false,
        showsSearch: true,

        usersHeaders: [
            { text: "NO", value: "no", divider: true, width: 50 },
            { text: "아이디", value: "username", divider: true, width: 160 },
            { text: "이름", value: "name", divider: true, width: 160 },
            { text: "연락처", value: "phone", divider: true, width: 160 },
            { text: "이메일", value: "email", divider: true, width: 160 },
            { text: "로그인 실패횟수", value: "loginFailedCount", divider: true, width: 100 },
            { text: "포인트", value: "point", divider: true, width: 120 },
            { text: "가입일자", value: "createdAt", divider: true, width: 170 },
            { text: "", value: "actions", align: "center", width: +60 },
        ],
        attrs_switch,
    }),
    computed: {
        items() {
            return this.users.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            delete query.page;

            return { ...query, scope: "member" };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        params() {
            this.init().then(this.search);
        },
    },
    methods: {
        async init() {
            try {
                this.users = [];
                this.summary = { totalCount: 0 };
            } catch (error) {
                this.$handleError(error);
            }
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
                const { summary, users } = await api.console.users.gets({ headers: { skip, limit }, params });

                this.users = users;
                this.summary = summary;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async update(user) {
            try {
                await api.console.users.put(user);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        updateItem(user) {
            const index = this.users.findIndex(({ _id }) => _id == user._id);
            if (0 <= index) this.users.splice(index, 1, user);
            else {
                this.users = [user, ...this.users];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async excel() {
            try {
                const { params } = this;
                var { users } = await api.console.users.gets({ params });

                var rows = [];
                users.forEach((user) => {
                    rows.push({
                        아이디: user?.username || "-",
                        이름: user?.name || "-",
                        연락처: user?.phone || "-",
                        이메일: user?.email || "-",
                        포인트: user?.point || "-",
                        회사이름: user?.company?.name || "-",
                        회사대표: user?.company?.ceo || "-",
                        사업자등록번호: user?.company?.businessNo || "-",
                        우편번호: user?.postcode || "-",
                        기본주소: user?.address1 || "-",
                        상세주소: user?.address2 || "-",
                        직급: user?.position || "-",
                        직책: user?.job || "-",
                        가입일자: user?.createdAt?.toDateTime(),
                    });
                });

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "회원목록.xlsx");
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>
